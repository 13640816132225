export const useVideoStore = defineStore('video', {
  state: () => {
    return {
      isActive: false,
      isOpen: false,
      isPublisherConnected: false,
      isSubscriberConnected: false,
      isPublisherMuted: false,
      isSubscriberMuted: false,
      isFullscreen: false,
      tokboxTokens: null,
    };
  },

  actions: {
  },

  getters: {
  },
});
